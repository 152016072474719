/* eslint-env browser */
(function() {
  var cache = {};
  var fragment;

  /**
   * @description Replaces link:import tag with its import content
   * @param {HTMLLinkElement} link Link tag
   */
  function importComponent(link) {
    if (!link.dataset.loading) {
      if (link.import && link.import.body) {
        if (!(link.href in cache)) {
          cache[link.href] = link.import.body.innerHTML;
        }
        fragment = document.createRange().createContextualFragment(cache[link.href]);
        link.parentNode.insertBefore(fragment, link);
        link.parentNode.removeChild(link);
        link = null;
        importComponents();
      } else {
        link.dataset.loading = true;
        link.addEventListener('load', importComponent);
      }
    }
  }

  /**
   * @description Searches all link:import and add load event callback
   */
  function importComponents() {
    var links = document.body.querySelectorAll('link[rel="import"]');
    [].forEach.call(links, importComponent);
  }

  window.App.onReady(importComponents);
})();
