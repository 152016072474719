/* eslint-env browser, jquery */
(function() {
  var TIMEOUT = 7000;
  /**
   * @description Initialize auto slider
   */
  function initialize() {
    $('.js-autoslide').each(function() {
      var _this = this;
      var $slide = $(_this);
      var $images = $slide.find('.js-autoslide-image');
      var $texts = $slide.find('.js-autoslide-text');
      var $indicators = $slide.find('.js-autoslide-indicators');
      _this.slideIndex = 0;
      var stop = function() {
        clearTimeout(_this.timeoutId);
      };
      var start = function() {
        stop();
        _this.timeoutId = setTimeout(toggle, TIMEOUT);
        $images.add($texts).addClass('-slider-item');
        setActive();
      };
      var toggle = function() {
        _this.slideIndex++;
        $images.add($texts).removeClass('-active');
        $indicators.children().removeClass('active');
        if (_this.slideIndex >= $images.length) {
          _this.slideIndex = 0;
        }
        setActive();
        start();
      };
      var setActive = function() {
        $images.eq(_this.slideIndex).add($texts.eq(_this.slideIndex)).addClass('-active');
        $indicators.children().eq(_this.slideIndex).addClass('active');
      };
      $indicators.on('click', '> *', function(e) {
        e.preventDefault();
        _this.slideIndex = $(e.currentTarget).index() - 1;
        toggle();
      });
      start();
    });
  }

  window.App.onReady(initialize);
})();
