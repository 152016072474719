/* eslint-env browser, jquery */
(function() {
  /**
   * @description Initialize jQuery date-picker component
   * @link https://github.com/uxsolutions/bootstrap-datepicker
   */
  function initialize() {
    $('.js-datepicker').datepicker({
      language: 'ru',
      format: 'dd.mm.yyyy',
      autoclose: true
    });
  }

  window.App.onReady(initialize);
})();
