/* eslint-env browser, jquery */
(function() {
  var $body = $(document.body);
  /**
   * @param {Boolean} state
   * @description Open filters and lock viewport
   */
  function toggle(state) {
    $body.toggleClass('-filters-opened', state);
  }

  window.App.onReady(function() {
    $(document)
      .on('click', '.js-filters-toggle', toggle)
      .on('click', '.js-filters-close', toggle.bind(null, false))
      .on('click', '.js-filters-open', toggle.bind(null, true));
  });
})();
