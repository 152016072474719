/* eslint-env browser, jquery */
(function() {
  /**
   * @description Initialize jQuery masked-input component
   */
  function initialize() {
    $('.js-masked-input').each(function() {
      var $this = $(this);
      var options = $this.data();
      $this.mask(options.mask, options);
    });
  }

  window.App.onReady(initialize);
})();
