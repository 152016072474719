/* eslint-env browser, jquery */
(function() {
  var $window = $(window);
  var $body = $(document.body);
  var updateTimeout;
  var targets = [];
  var windowHeight = $window.height();

  /**
   * Check if target can load content
   * @param {HTMLElement} target
   * @return {boolean}
   */
  function checkTarget(target) {
    var rect = target.getBoundingClientRect();
    return !target.lock && (rect.top + rect.height < 1.25 * windowHeight);
  }

  /**
   * Load remote content to the target element
   * @param {HTMLElement} target
   */
  function updateTarget(target) {
    if (checkTarget(target)) {
      target.lock = true;
      var $target = $(target);
      var data = [{
        name: 'offset',
        value: $target.children().length
      }];
      var serializedArrayData = target.onscroll && target.onscroll();
      if (serializedArrayData) {
        data = data.concat(serializedArrayData);
      }
      $.ajax({
        url: $target.data('remoteUrl'),
        data: $.param(data)
      }).done(function(response) {
        if (response) {
          $target.append(response);
          target.lock = false;
        } else {
          $target.addClass('-loaded');
          removeRemoteComponent(target);
        }
      });
    }
  }

  /**
   * Updates global values and targets
   */
  function updateTargets() {
    windowHeight = $window.height();
    targets.forEach(updateTarget);
  }

  /**
   * Safe initialization
   */
  function initialize() {
    clearTimeout(updateTimeout);
    updateTimeout = setTimeout(updateTargets, 100);
  }

  /**
   * Remove
   * @param {HTMLElement} target
   */
  function removeRemoteComponent(target) {
    var index = targets.indexOf(target);
    if (index > -1) {
      targets.splice(index, 1);
    }
  }

  /**
   * Add target element to watcher
   * @param {HTMLElement} target
   */
  function addRemoteComponent(target) {
    targets.push(target);
  }

  window.App.remoteComponent = addRemoteComponent;

  window.App.onReady(function() {
    $body.find('[data-remote-url]').each(function() {
      addRemoteComponent(this);
    });
    $window.on('scroll', initialize);
  });
})();
