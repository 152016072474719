/* eslint-env browser, jquery */
(function() {
  var $body = $(document.body);
  /**
   * @description Open menu and lock viewport
   */
  function toggleMenu() {
    $body.toggleClass('-menu-opened');
  }

  window.App.onReady(function() {
    $(document).on('click', '.js-menu-open, .js-menu-close', toggleMenu);
  });
})();
