/*
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function() {
  'use strict';

  var listened = false;
  var App = window.App || {};
  var onReadyCallbacks = App.onReadyCallbacks || [];

  /**
   * @description Calls cached callbacks
   */
  function callOnReadyCallbacks() {
    while (onReadyCallbacks.length) {
      onReadyCallbacks.shift().call();
    }
  }

  App.onReady = function(cb) {
    if (typeof cb !== 'function') {
      return;
    }
    if (document.readyState === 'complete') {
      cb();
    } else {
      if (!listened) {
        listened = true;
        window.addEventListener('DOMContentLoaded', callOnReadyCallbacks);
      }
      onReadyCallbacks.push(cb);
    }
  };

  App.isMediaQueryActive = function(type) {
    var res = false;
    var queries = {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    };
    if (type in queries) {
      res = window.matchMedia('(min-width: ' + queries[type] + 'px)');
      res = res.matches;
    }
    return res;
  };

  /*var windowOnError = window.onerror || function() {};
  window.onerror = function(errorMsg, url, lineNumber) {
    var args = [errorMsg, url, lineNumber];
    var err = document.createElement('pre');
    err.innerHTML = JSON.stringify(args, null, 2);
    document.body.insertBefore(err, document.body.firstChild);
    return windowOnError(errorMsg, url, lineNumber);
  };*/

  window.App = App;
})();
