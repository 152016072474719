/* eslint-env browser, jquery */
(function() {
  var $window = $(window);
  var $body = $(document.body);
  var TIMEOUT = 150;
  var timeoutId;

  /**
   * @description Add class to body tag to know when window are scrolled down
   */
  function checkScrollValue() {
    $body.toggleClass('-scrolled', $window.scrollTop() > 0);
  }

  /**
   * @description Prevent many scroll callback fires
   */
  function onScrollHandler() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(checkScrollValue, TIMEOUT);
  }

  /**
   * @description Add window scroll event listener
   */
  function initialize() {
    $window.on('scroll', onScrollHandler);
  }

  window.App.onReady(initialize);
})();
